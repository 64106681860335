.userPrompt{
    color: #FFF;
    font-family: 'Inter';
    font-size: 16px;
    font-style: normal;
    font-weight: 400;
    line-height: 115%; /* 18.4px */
    padding: 9px 25px;
    border-radius: 4px;
    background: #1D1F20;
    margin-bottom: 45px;
}
.singlePrompt .result-container {
    @media (min-width:768px) {
        overflow-y: scroll;
        /* overflow-y: auto; */
    }
}
.result-container{
    max-width: 853px;
    margin-bottom: 64px;

    &::-webkit-scrollbar {
        width: 4px; /* for vertical scrollbars */
        height: 4px; /* for horizontal scrollbars */
    }
    &::-webkit-scrollbar-track {
        background: rgba(255, 255, 255, 0.1);
    }
    &::-webkit-scrollbar-thumb {
        background: #D9D9D9;
        border-radius: 10px;
        /* box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.5); */
    }
    @media (max-width:768px){
        margin-bottom: 45px;
        overflow-y: auto;
    }
}

.result-container:last-child {
    margin-bottom: 0; /* Remove margin for the last .result-container */
}

.promptResultDiv{
    position: relative;
    &::before{
        content: '';
        position: absolute;
        top: 0;
        left: -61px;
        width: 38px;
        height: 38px;
        background-image: url('../../assets/promptIcon.svg');
    }
}

.promptResultTitle{
    color: #FFF;
    font-family: 'Inter';
    font-size: 16px;
    font-style: normal;
    font-weight: 600;
    line-height: 115%; /* 18.4px */
}

.promptResultContent{
    color: #ECECEC;
    font-family: 'Inter';
    font-size: 16px;
    font-style: normal;
    font-weight: 400;
    line-height: 115%; /* 18.4px */
    overflow: hidden;
    /* white-space: pre-wrap; */
    white-space: pre-line;

}

.divider{
    background: #8C8C8C;
    /* width: 1px; */
    height: 1px;
    margin: 25px 0;
}

.actionButtonDiv{
    border-radius: 6px;
    background: #212121;
    padding: 10px;
    gap: 16px;
    width: fit-content;
}