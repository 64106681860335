.mainComponent{
    min-height: 100vh;
    padding: 50px 0;
    justify-content: center;
    @media (max-width:768px){
        height: 100%;
        padding: 50px 24px;
        min-height: 100vh;
    }
}
.userName{
    color: #FFF;
    text-align: center;
    font-family: 'Inter';
    font-size: 30px;
    font-style: normal;
    font-weight: 400;
    line-height: 115%; /* 34.5px */
}

.mainComponentContainer{
    max-width: 853px;
}

.userName{
    color: #FFF;
    text-align: center;
    font-family: 'Inter';
    font-size: 30px;
    font-style: normal;
    font-weight: 400;
    line-height: 115%; /* 34.5px */
    margin-bottom: 24px;
}

.subTitle{
    color: rgba(255, 255, 255, 0.85);
    font-family: 'Inter';
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: 115%; /* 16.1px */
    margin-bottom: 0;
    text-align: center;
}

.userDiv{
    margin-bottom: 82px;
    @media (max-width:768px) {
        margin-bottom: 48px;
    }
}

.topPromptsDiv{
    margin-bottom: 48px;
}

.topPromptTitle{
    color: #FFF;
    font-family: 'Inter';
    font-size: 16px;
    font-style: normal;
    font-weight: 400;
    line-height: 115%; /* 18.4px */
    margin-bottom: 24px;
    text-align: center;
}

.topPromptCard{
    padding: 20px 10px;
    border-radius: 2px;
    border: 1px solid #333;
    width: 100%;
    &:hover{
        background-color: #1D1F20;
    }
}

.topPromptFunction{
    color: #FFF;
    font-family: 'Inter';
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: 115%; /* 16.1px */
    margin-bottom: 4px;
}
.topPrompt{
    color: #FFF;
    font-family: 'Outfit';
    font-size: 12px;
    font-style: normal;
    font-weight: 200;
    line-height: 115%; /* 13.8px */
    margin-bottom: 0;
}

.userInputDiv{
    @media (max-width:768px){
        gap: 20px;
        width: 100%;
    }
}
.inputPrompt {
    gap: 14px;
}

.userInput{
    gap:10px;
}
.selectFunctionsDiv{
    border-radius: 4px;
    background: #1D1F20;
    padding: 9px 25px;
    margin-right: 20px;
    max-width: 100%;
    width: 223px;
    height: fit-content;
    min-height: 44px;
    @media (max-width:768px){
        margin-right: 0;
        width: 100%;
        min-width: 223px;
    }
}
.selectFunctions{
    width: 223px;
    max-width: 100%;
    @media (max-width:768px) {
        width: 100%;
    }
}
.selectFunctionsTitle{
    color: #FFF;
    font-family: 'Inter';
    font-size: 16px;
    font-style: normal;
    font-weight: 400;
    line-height: 115%; /* 18.4px */
    margin-bottom: 0;
    text-align: left;
    /* gap: 8px; */
    /* margin-right: 30px; */
}
.selectFunctionsArrow{
    transform: rotate(180deg);
}

.userInputField{
    border-radius: 4px;
    background: #1D1F20;
    padding: 9px 25px;
    border: none;
    width: 498px;
    max-width: 100%;
    color: #FFF;
    /* margin-right: 14px; */
    gap: 14px;
    /* height: 44px; */
    field-sizing: content;

    &:focus-visible{
        outline: none;
    }

    @media (max-width:768px) {
        width: 100%;;
    }
}
.AISubmit{
    border: none;
    padding: 9px 25px;
    border-radius: 4px;
    background: #1D1F20;
    height: 44px;
    @media (max-width:768px){
        padding: 6px 25px;
    }
}

.dropdown-menu{
    position: absolute;
    bottom: 60px;
    left: 0;
    width: 100%;
    padding: 5px 0;
    border-radius: 4px;
    background: #1D1F20;
    color: #FFF;
}
.dropdown-menu li label{
    color: #FFF;
    font-family: 'Inter';
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: 115%; /* 16.1px */
    text-wrap: auto;
}
.dropdown-item{
    padding: 5px 25px;
}
.dropdown-item:focus, .dropdown-item:hover, .dropdown-item:active{
    background-color: transparent;
    color: #FFF;
}
.selectFunctionsBtn{
    background: #1D1F20;
    color: #FFF;
    border: none;
    width: 100%;
    padding: 0;
}

.dropdown .form-check-input:checked, .dropdown .form-check-input[type=checkbox]{
    background-color: transparent;
    border-color: #696969;
    margin-right: 12px;
    border-radius: 0;
}
.form-check-input:checked[type=checkbox]{
    --bs-form-check-bg-image: url('../../assets/check.svg')
}
.form-check-input{
    width: 18px;
    height: 18px;
}
.form-check-input:focus{
    box-shadow: none;
}
span.functionsCount {
    border-radius: 32px;
    border: 0.3px solid #5F6365;
    padding: 2px 8px;
    margin-left: 8px;
}

.isBlur{
    filter: blur(2px);
}

.AllPrompts{
    overflow-y: scroll;
    justify-content: start;
    &::-webkit-scrollbar {
        width: 4px; /* for vertical scrollbars */
        height: 4px; /* for horizontal scrollbars */
    }
    &::-webkit-scrollbar-track {
        background: rgba(255, 255, 255, 0.1);
    }
    &::-webkit-scrollbar-thumb {
        background: #D9D9D9;
        border-radius: 10px;
        /* box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.5); */
    }
}