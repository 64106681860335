.sideBarDiv{
    height: 100vh;
    position: relative;
    background-color: #000;
    padding: 30px 24px;    
    z-index: 9;
    height: 100%;
    &::before{
        content:'';
        position: absolute;
        top: 0;
        left: 0;
        bottom: 0;
        z-index: -1;
        width: 261px;
        height: auto;
        background-image: url('../../assets/Group\ 1.png');
        background-repeat: no-repeat;
    }
    &::after{
        content:'';
        position: absolute;
        right: 0;
        bottom: 0;
        z-index: -1;
        width: 314px;
        height: 328px;
        background-image: url('../../assets/Group\ 2.png');
        background-repeat: no-repeat;

    }
}

.newChatDiv{
    padding: 6px 30px;
    border-radius: 4px;
    background: #1D1F20;
    margin-bottom: 25px;
}
.userProfile{
    border-radius: 4px;
    background: #1D1F20;
    margin-bottom: 0;
    margin-top: 14px;
    padding: 8px 14px;
}
.profileName{
    color: #FFF;
    font-family: Inter;
    font-size: 20px;
    font-style: normal;
    font-weight: 400;
    line-height: 115%; /* 23px */
}
.profileEmail{
    color: #858585;
    font-family: 'Inter';
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: 115%; /* 16.1px */
    word-break: break-word;
    text-align: left;
}

.newChatText{
    color: #FFF;
    font-family: 'Inter';
    font-size: 18px;
    font-style: normal;
    font-weight: 400;
    margin-bottom: 0;
}

hr{
    color: #FFF;
    margin: 9px 0;
    border-radius: 278px;
    background: rgba(217, 217, 217, 0.55);
    width: 100%;
}

.accordion-button {
    padding: 18px;
    background-color: transparent;
    &::after{
        margin-left: 6px;
        background-image: url('../../assets/accordionIcon.svg');
    }
    &:not(.collapsed){
        background-color: transparent;
        box-shadow: none;
    }
    &:not(.collapsed)::after{
        background-image: url('../../assets/accordionIcon.svg');
        transform:rotate(-180deg);
    }
}

.accordion-body{
    padding-left: 46px;
    padding-top: 0;
    /* margin-top: -12px; */
    max-height: 205px;
    overflow-y: scroll;
    &::-webkit-scrollbar {
        width: 4px; /* for vertical scrollbars */
        height: 4px; /* for horizontal scrollbars */
    }
    &::-webkit-scrollbar-track {
        background: rgba(255, 255, 255, 0.1);
    }
    &::-webkit-scrollbar-thumb {
        background: #D9D9D9;
        border-radius: 10px;
        /* box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.5); */
    }
}

.SideBarTitle{
    color: #FFF;
    font-family: 'Inter';
    font-size: 16px;
    font-style: normal;
    font-weight: 500;
    line-height: 115%;
}

.list-group{
    gap: 11px;
}

.list-item button{
    color: #AFB1B2;
    font-family: 'Inter';
    font-size: 14px;
    font-style: normal;
    font-weight: 500;
    line-height: 115%; /* 16.1px */
    text-decoration: none;
    display: -webkit-box;
    -webkit-line-clamp: 1;
    -webkit-box-orient: vertical;
    overflow: hidden;
    text-overflow: ellipsis;
    text-align: left;
    &:hover, &:active{
        color: #FFF !important;
    }

}

/* styles.css */
.custom-popover {
    background-color: #1D1F20; /* Set the background color */
    color: white; /* Optional: Set text color for better contrast */
    border: none; /* Optional: Remove border */
}

.custom-popover .popover-body {
    padding: 10px; /* Optional: Add padding to the body */
}

.historyDiv{
    padding: 18px;
}

.emptyList{
    color: #AFB1B2;
    font-family: 'Inter';
    font-size: 14px;
    font-style: normal;
    font-weight: 500;
    line-height: 115%;
    text-decoration: none;
    display: -webkit-box;
    -webkit-line-clamp: 1;
    -webkit-box-orient: vertical;
    overflow: hidden;
    text-overflow: ellipsis;
    text-align: left;
}
