@import url('https://fonts.googleapis.com/css2?family=Inter:ital,opsz,wght@0,14..32,100..900;1,14..32,100..900&family=Outfit:wght@100..900&display=swap');

:root{
    overflow-x: hidden;
}
body{
    background-color: rgb(21, 21, 21) !important;
}

.swal2-popup.swal2-modal{
    border-radius: 12px;
    border: 1px solid #333;
    background: #151515;
}
div:where(.swal2-container) div:where(.swal2-html-container), div:where(.swal2-container) h2:where(.swal2-title){
    color: #e1e1e1 !important;
}

button.swal2-confirm.swal2-styled {
    color: #151515;
    font-family: Inter;
    font-size: 14px;
    font-style: normal;
    font-weight: 600;
    line-height: 115%;
    background-color: #9DD23E;
    border-radius: 37px;
}

.customModal .modal-content{
    border-radius: 12px;
    border: 1px solid #333;
    background: #151515;
    color: #fff;
}

.customModal .modal-footer button{
    color: #151515;
    font-family: 'Inter';
    font-size: 14px;
    font-style: normal;
    font-weight: 600;
    line-height: 115%; 
    border-radius: 37px;
}
.customModal .modal-header button{
    filter: invert(1) grayscale(100%) brightness(200%);
}

.customModal .SaveBtn{
    background:  #9DD23E;
    border: none;
    &:hover{
        background:  #9DD23E;        
    }
}   

.customModal .form-check-input:checked {
    /* background-color: #9DD23E;
    border-color: #9DD23E; */
    filter: invert(1) ;
}

.btnOffcanvasClose{
    position: absolute;
    top: 20px;
    right: 20px;
    z-index: 9999;
    filter: invert(1);
}

.NoPromptFoundImage{
    width: 500px;
    max-width: 100%;

}

.navDiv{
    padding: 40px 24px 20px !important;
    background-color: #151515;
}