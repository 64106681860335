
.LoginScreen{
  background-image: url('../../assets/bgImage.png');
  background-position: center;
  background-size: cover;
  background-repeat: no-repeat;
  width: 100%;
  height: 100vh;
}
.bannerImage{
  background-image: url('../../assets/bannerImage.png');
  background-position: left center;
  background-repeat: no-repeat;
  width: 100%;
  height: 100vh;
  background-size: cover;
  @media (max-width:768px){
    width: 100vw;
    height: 50vh;
  }
}

.loginContainer{
  padding: 80px 83px;
  border-radius: 17.618px;
  border: 1px solid rg  ba(233, 233, 233, 0.28);
  background: rgba(153, 153, 153, 0.17);
  backdrop-filter: blur(3.1500000953674316px);
  @media (max-width:768px){
    padding: 40px 20px;
  }
}

.divLoginContainer{
  @media (max-width: 768px){
    height: 50vh;
  }
}

.companyLogo{
  margin-bottom: 36px;
}

.singIn{
  color: #FFF;
  text-align: center;
  font-family: 'Inter';
  font-size: 22px;
  font-style: normal;
  font-weight: 600;
  line-height: normal;
  margin-bottom: 30px;
}

.microsoftSSO{
  /* margin-bottom: 18px; */
}